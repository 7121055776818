.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: '';
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
}

[class^='ui-'], [class^='ui-']:after, [class^='ui-']:before {
  box-sizing: content-box;
}

.ui-widget {
  font-family: SofiaProRegular, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.ui-widget-content {
  border: 1px solid #EFF5F8;
  background: #ffffff;
  color: #000000;
}

.ui-widget-content a {
  color: #000000;
}

.ui-widget-header {
  border: 1px solid #EFF5F8;
  color: #000000;
  font-weight: 600;
  background: #F9FBFC;
}

.ui-widget-header a {
  color: #000000;
}

.ui-widget-overlay {
  background: #E7F4FA;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #A7A9AC;
  background: #EAEAEA;
  color: #696969;
  font-weight: normal;
}

.ui-state-default, .ui-state-default:active, .ui-state-default:hover, .ui-state-default:focus,
.ui-state-default a,
.ui-state-default a:active,
.ui-state-default a:hover,
.ui-state-default a:focus,
.ui-widget-content .ui-state-default,
.ui-widget-content .ui-state-default:active,
.ui-widget-content .ui-state-default:hover,
.ui-widget-content .ui-state-default:focus,
.ui-widget-content .ui-state-default a,
.ui-widget-content .ui-state-default a:active,
.ui-widget-content .ui-state-default a:hover,
.ui-widget-content .ui-state-default a:focus,
.ui-widget-header .ui-state-default,
.ui-widget-header .ui-state-default:active,
.ui-widget-header .ui-state-default:hover,
.ui-widget-header .ui-state-default:focus,
.ui-widget-header .ui-state-default a,
.ui-widget-header .ui-state-default a:active,
.ui-widget-header .ui-state-default a:hover,
.ui-widget-header .ui-state-default a:focus {
  color: #696969;
  text-decoration: none;
}

.ui-state-default-text,
.ui-widget-content .ui-state-default-text,
.ui-widget-header .ui-state-default-text {
  color: #696969;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover {
  border: 1px solid #A7A9AC;
  background: #EAEAEA;
  color: #696969;
  font-weight: normal;
}

.ui-state-hover, .ui-state-hover:active, .ui-state-hover:hover, .ui-state-hover:focus,
.ui-state-hover a,
.ui-state-hover a:active,
.ui-state-hover a:hover,
.ui-state-hover a:focus,
.ui-widget-content .ui-state-hover,
.ui-widget-content .ui-state-hover:active,
.ui-widget-content .ui-state-hover:hover,
.ui-widget-content .ui-state-hover:focus,
.ui-widget-content .ui-state-hover a,
.ui-widget-content .ui-state-hover a:active,
.ui-widget-content .ui-state-hover a:hover,
.ui-widget-content .ui-state-hover a:focus,
.ui-widget-header .ui-state-hover,
.ui-widget-header .ui-state-hover:active,
.ui-widget-header .ui-state-hover:hover,
.ui-widget-header .ui-state-hover:focus,
.ui-widget-header .ui-state-hover a,
.ui-widget-header .ui-state-hover a:active,
.ui-widget-header .ui-state-hover a:hover,
.ui-widget-header .ui-state-hover a:focus {
  color: #696969;
  text-decoration: none;
}

.ui-state-hover-text,
.ui-widget-content .ui-state-hover-text,
.ui-widget-header .ui-state-hover-text {
  color: #696969;
}

.ui-state-focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid #A7A9AC;
  background: #EAEAEA;
  color: #696969;
  font-weight: normal;
}

.ui-state-focus, .ui-state-focus:active, .ui-state-focus:hover, .ui-state-focus:focus,
.ui-state-focus a,
.ui-state-focus a:active,
.ui-state-focus a:hover,
.ui-state-focus a:focus,
.ui-widget-content .ui-state-focus,
.ui-widget-content .ui-state-focus:active,
.ui-widget-content .ui-state-focus:hover,
.ui-widget-content .ui-state-focus:focus,
.ui-widget-content .ui-state-focus a,
.ui-widget-content .ui-state-focus a:active,
.ui-widget-content .ui-state-focus a:hover,
.ui-widget-content .ui-state-focus a:focus,
.ui-widget-header .ui-state-focus,
.ui-widget-header .ui-state-focus:active,
.ui-widget-header .ui-state-focus:hover,
.ui-widget-header .ui-state-focus:focus,
.ui-widget-header .ui-state-focus a,
.ui-widget-header .ui-state-focus a:active,
.ui-widget-header .ui-state-focus a:hover,
.ui-widget-header .ui-state-focus a:focus {
  color: #696969;
  text-decoration: none;
}

.ui-state-focus-text,
.ui-widget-content .ui-state-focus-text,
.ui-widget-header .ui-state-focus-text {
  color: #696969;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #dad55e;
  background: #fffa90;
  color: #777620;
  font-weight: normal;
}

.ui-state-highlight, .ui-state-highlight:active, .ui-state-highlight:hover, .ui-state-highlight:focus,
.ui-state-highlight a,
.ui-state-highlight a:active,
.ui-state-highlight a:hover,
.ui-state-highlight a:focus,
.ui-widget-content .ui-state-highlight,
.ui-widget-content .ui-state-highlight:active,
.ui-widget-content .ui-state-highlight:hover,
.ui-widget-content .ui-state-highlight:focus,
.ui-widget-content .ui-state-highlight a,
.ui-widget-content .ui-state-highlight a:active,
.ui-widget-content .ui-state-highlight a:hover,
.ui-widget-content .ui-state-highlight a:focus,
.ui-widget-header .ui-state-highlight,
.ui-widget-header .ui-state-highlight:active,
.ui-widget-header .ui-state-highlight:hover,
.ui-widget-header .ui-state-highlight:focus,
.ui-widget-header .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a:active,
.ui-widget-header .ui-state-highlight a:hover,
.ui-widget-header .ui-state-highlight a:focus {
  color: #777620;
  text-decoration: none;
}

.ui-state-highlight-text,
.ui-widget-content .ui-state-highlight-text,
.ui-widget-header .ui-state-highlight-text {
  color: #777620;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #A7A9AC;
  background: #EAEAEA;
  color: #696969;
  font-weight: normal;
}

.ui-state-active, .ui-state-active:active, .ui-state-active:hover, .ui-state-active:focus,
.ui-state-active a,
.ui-state-active a:active,
.ui-state-active a:hover,
.ui-state-active a:focus,
.ui-widget-content .ui-state-active,
.ui-widget-content .ui-state-active:active,
.ui-widget-content .ui-state-active:hover,
.ui-widget-content .ui-state-active:focus,
.ui-widget-content .ui-state-active a,
.ui-widget-content .ui-state-active a:active,
.ui-widget-content .ui-state-active a:hover,
.ui-widget-content .ui-state-active a:focus,
.ui-widget-header .ui-state-active,
.ui-widget-header .ui-state-active:active,
.ui-widget-header .ui-state-active:hover,
.ui-widget-header .ui-state-active:focus,
.ui-widget-header .ui-state-active a,
.ui-widget-header .ui-state-active a:active,
.ui-widget-header .ui-state-active a:hover,
.ui-widget-header .ui-state-active a:focus {
  color: #696969;
  text-decoration: none;
}

.ui-state-active-text,
.ui-widget-content .ui-state-active-text,
.ui-widget-header .ui-state-active-text {
  color: #696969;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f;
  font-weight: normal;
}

.ui-state-error, .ui-state-error:active, .ui-state-error:hover, .ui-state-error:focus,
.ui-state-error a,
.ui-state-error a:active,
.ui-state-error a:hover,
.ui-state-error a:focus,
.ui-widget-content .ui-state-error,
.ui-widget-content .ui-state-error:active,
.ui-widget-content .ui-state-error:hover,
.ui-widget-content .ui-state-error:focus,
.ui-widget-content .ui-state-error a,
.ui-widget-content .ui-state-error a:active,
.ui-widget-content .ui-state-error a:hover,
.ui-widget-content .ui-state-error a:focus,
.ui-widget-header .ui-state-error,
.ui-widget-header .ui-state-error:active,
.ui-widget-header .ui-state-error:hover,
.ui-widget-header .ui-state-error:focus,
.ui-widget-header .ui-state-error a,
.ui-widget-header .ui-state-error a:active,
.ui-widget-header .ui-state-error a:hover,
.ui-widget-header .ui-state-error a:focus {
  color: #5f3f3f;
  text-decoration: none;
}

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #5f3f3f;
}

.ui-state-disabled {
  cursor: default !important;
  pointer-events: none;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: 0.35;
  background-image: none;
}

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold;
}

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: 0.5;
  font-weight: normal;
}

@font-face {
  font-family: 'jquery-ui';
  src: url("../fonts/jquery-ui/jquery-ui.eot?jquf1.4.1");
  src: url("../fonts/jquery-ui/jquery-ui.eot?#iefixjquf1.4.1") format("embedded-opentype"), url("../fonts/jquery-ui/jquery-ui.woff?jquf1.4.1") format("woff"), url("../fonts/jquery-ui/jquery-ui.ttf?jquf1.4.1") format("truetype"), url("../fonts/jquery-ui/jquery-ui.svg?jquf1.4.1#jquery-ui") format("svg");
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
}

.ui-icon,
.ui-busy-icon {
  display: inline;
  background: transparent none no-repeat scroll 0 0 !important;
  font: normal normal normal 14px/16px 'jquery-ui', sans-serif;
  overflow: hidden;
  padding: 0;
  margin: 0;
  position: relative;
  line-height: inherit;
  text-transform: none;
  transform: translate(0, 0);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0;
}

.ui-icon:before,
.ui-busy-icon:before {
  display: inline;
  content: '';
  font-size: 16px;
}

.ui-icon-arrow-1-n:before {
  content: '\e600';
}

.ui-icon-arrow-u:before {
  content: '\e600';
}

.ui-icon-arrow-1-ne:before {
  content: '\e601';
}

.ui-icon-arrow-u-r:before {
  content: '\e601';
}

.ui-icon-arrow-1-e:before {
  content: '\e602';
}

.ui-icon-arrow-r:before {
  content: '\e602';
}

.ui-icon-arrow-1-se:before {
  content: '\e603';
}

.ui-icon-arrow-d-r:before {
  content: '\e603';
}

.ui-icon-arrow-1-s:before {
  content: '\e604';
}

.ui-icon-arrow-d:before {
  content: '\e604';
}

.ui-icon-arrow-1-sw:before {
  content: '\e605';
}

.ui-icon-arrow-d-l:before {
  content: '\e605';
}

.ui-icon-arrow-1-w:before {
  content: '\e606';
}

.ui-icon-arrow-l:before {
  content: '\e606';
}

.ui-icon-arrow-1-nw:before {
  content: '\e607';
}

.ui-icon-arrow-u-l:before {
  content: '\e607';
}

.ui-icon-arrowstop-1-n:before {
  content: '\e608';
}

.ui-icon-arrowstop-1-e:before {
  content: '\e609';
}

.ui-icon-arrowstop-1-s:before {
  content: '\e60a';
}

.ui-icon-arrowstop-1-w:before {
  content: '\e60b';
}

.ui-icon-arrowreturn-1-n:before {
  content: '\e60c';
}

.ui-icon-arrowreturn-1-e:before {
  content: '\e60d';
}

.ui-icon-forward:before {
  content: '\e60d';
}

.ui-icon-arrowreturn-1-s:before {
  content: '\e60e';
}

.ui-icon-arrowreturn-1-w:before {
  content: '\e60f';
}

.ui-icon-back:before {
  content: '\e60f';
}

.ui-icon-arrowrefresh-1-n:before {
  content: '\e610';
}

.ui-icon-arrowrefresh-1-e:before {
  content: '\e611';
}

.ui-icon-arrowrefresh-1-s:before {
  content: '\e612';
}

.ui-icon-arrowrefresh-1-w:before {
  content: '\e613';
}

.ui-icon-arrow-2-n-s:before {
  content: '\e614';
}

.ui-icon-arrow-2-ne-sw:before {
  content: '\e615';
}

.ui-icon-arrow-2-e-w:before {
  content: '\e616';
}

.ui-icon-arrow-2-se-nw:before {
  content: '\e617';
}

.ui-icon-arrow-4:before {
  content: '\e618';
}

.ui-icon-move:before {
  content: '\e618';
}

.ui-icon-arrow-4-diag:before {
  content: '\e619';
}

.ui-icon-fullscreen:before {
  content: '\e619';
}

.ui-icon-arrowthick-1-n:before {
  content: '\e61a';
}

.ui-icon-arrowthick-1-ne:before {
  content: '\e61b';
}

.ui-icon-arrowthick-1-e:before {
  content: '\e61c';
}

.ui-icon-arrowthick-1-se:before {
  content: '\e61d';
}

.ui-icon-arrowthick-1-s:before {
  content: '\e61e';
}

.ui-icon-arrowthick-1-sw:before {
  content: '\e61f';
}

.ui-icon-arrowthick-1-w:before {
  content: '\e620';
}

.ui-icon-arrowthick-1-nw:before {
  content: '\e621';
}

.ui-icon-arrowthickstop-1-n:before {
  content: '\e622';
}

.ui-icon-arrowthickstop-1-e:before {
  content: '\e623';
}

.ui-icon-arrowthickstop-1-s:before {
  content: '\e624';
}

.ui-icon-arrowthickstop-1-w:before {
  content: '\e625';
}

.ui-icon-arrowreturnthick-1-n:before {
  content: '\e626';
}

.ui-icon-arrowreturnthick-1-e:before {
  content: '\e627';
}

.ui-icon-arrowreturnthick-1-s:before {
  content: '\e628';
}

.ui-icon-arrowreturnthick-1-w:before {
  content: '\e629';
}

.ui-icon-arrowthick-2-n-s:before {
  content: '\e62a';
}

.ui-icon-arrowthick-2-ne-sw:before {
  content: '\e62b';
}

.ui-icon-arrowthick-2-e-w:before {
  content: '\e62c';
}

.ui-icon-arrowthick-2-se-nw:before {
  content: '\e62d';
}

.ui-icon-circle-arrow-n:before {
  content: '\e62e';
}

.ui-icon-circle-arrow-e:before {
  content: '\e62f';
}

.ui-icon-circle-arrow-s:before {
  content: '\e630';
}

.ui-icon-circle-arrow-w:before {
  content: '\e631';
}

.ui-icon-circle-b-arrow-n:before {
  content: '\e632';
}

.ui-icon-circle-b-arrow-e:before {
  content: '\e633';
}

.ui-icon-circle-b-arrow-s:before {
  content: '\e634';
}

.ui-icon-circle-b-arrow-w:before {
  content: '\e635';
}

.ui-icon-carat-1-n:before {
  content: '\e636';
}

.ui-icon-caret-u:before {
  content: '\e636';
}

.ui-icon-carat-1-ne:before {
  content: '\e637';
}

.ui-icon-carat-1-e:before {
  content: '\e638';
}

.ui-icon-caret-r:before {
  content: '\e638';
}

.ui-icon-carat-1-se:before {
  content: '\e639';
}

.ui-icon-carat-1-s:before {
  content: '\e63a';
}

.ui-icon-caret-d:before {
  content: '\e63a';
}

.ui-icon-carat-1-sw:before {
  content: '\e63b';
}

.ui-icon-carat-1-w:before {
  content: '\e63c';
}

.ui-icon-caret-l:before {
  content: '\e63c';
}

.ui-icon-carat-1-nw:before {
  content: '\e63d';
}

.ui-icon-carat-2-n:before {
  content: '\e63e';
}

.ui-icon-carat-2-e:before {
  content: '\e63f';
}

.ui-icon-carat-2-s:before {
  content: '\e640';
}

.ui-icon-carat-2-w:before {
  content: '\e641';
}

.ui-icon-carat-2-n-s:before {
  content: '\e642';
}

.ui-icon-carat-2-e-w:before {
  content: '\e643';
}

.ui-icon-triangle-1-n:before {
  content: '\e644';
}

.ui-icon-triangle-1-ne:before {
  content: '\e645';
}

.ui-icon-triangle-1-e:before {
  content: '\e646';
}

.ui-icon-triangle-1-se:before {
  content: '\e647';
}

.ui-icon-triangle-1-s:before {
  content: '\e648';
}

.ui-icon-triangle-1-sw:before {
  content: '\e649';
}

.ui-icon-triangle-1-w:before {
  content: '\e64a';
}

.ui-icon-triangle-1-nw:before {
  content: '\e64b';
}

.ui-icon-triangle-1-n-stop:before {
  content: '\e64c';
}

.ui-icon-triangle-1-e-stop:before {
  content: '\e64d';
}

.ui-icon-seek-end:before {
  content: '\e64d';
}

.ui-icon-triangle-1-s-stop:before {
  content: '\e64e';
}

.ui-icon-triangle-1-w-stop:before {
  content: '\e64f';
}

.ui-icon-seek-first:before {
  content: '\e64f';
}

.ui-icon-triangle-2-e:before {
  content: '\e650';
}

.ui-icon-seek-next:before {
  content: '\e650';
}

.ui-icon-triangle-2-w:before {
  content: '\e651';
}

.ui-icon-seek-prev:before {
  content: '\e651';
}

.ui-icon-triangle-2-n-s:before {
  content: '\e652';
}

.ui-icon-triangle-2-e-w:before {
  content: '\e653';
}

.ui-icon-circle-triangle-n:before {
  content: '\e654';
}

.ui-icon-circle-triangle-e:before {
  content: '\e655';
}

.ui-icon-circle-triangle-s:before {
  content: '\e656';
}

.ui-icon-circle-triangle-w:before {
  content: '\e657';
}

.ui-icon-circle-b-triangle-n:before {
  content: '\e658';
}

.ui-icon-circle-b-triangle-e:before {
  content: '\e659';
}

.ui-icon-circle-b-triangle-s:before {
  content: '\e65a';
}

.ui-icon-circle-b-triangle-w:before {
  content: '\e65b';
}

.ui-icon-bullet:before {
  content: '\e65c';
}

.ui-icon-circle:before {
  content: '\e65d';
}

.ui-icon-radio-off:before {
  content: '\e65d';
}

.ui-icon-alert:before {
  content: '\e65e';
}

.ui-icon-alert-b:before {
  content: '\e65f';
}

.ui-icon-help-plain:before {
  content: '\e660';
}

.ui-icon-help:before {
  content: '\e661';
}

.ui-icon-circle-help:before {
  content: '\e661';
}

.ui-icon-circle-b-help:before {
  content: '\e662';
}

.ui-icon-info-plain:before {
  content: '\e663';
}

.ui-icon-info:before {
  content: '\e664';
}

.ui-icon-circle-info:before {
  content: '\e664';
}

.ui-icon-circle-b-info:before {
  content: '\e665';
}

.ui-icon-notice-plain:before {
  content: '\e666';
}

.ui-icon-notice:before {
  content: '\e667';
}

.ui-icon-circle-notice:before {
  content: '\e667';
}

.ui-icon-circle-b-notice:before {
  content: '\e668';
}

.ui-icon-grip-diagonal-se:before {
  content: '\e669';
}

.ui-icon-gripsmall-diagonal-se:before {
  content: '\e66a';
}

.ui-icon-grip-solid-horizontal:before {
  content: '\e66b';
}

.ui-icon-grip-dotted-horizontal:before {
  content: '\e66c';
}

.ui-icon-grip-solid-vertical:before {
  content: '\e66d';
}

.ui-icon-grip-dotted-vertical:before {
  content: '\e66e';
}

.ui-icon-check:before {
  content: '\e66f';
}

.ui-icon-circle-check:before {
  content: '\e670';
}

.ui-icon-circle-b-check:before {
  content: '\e671';
}

.ui-icon-check-off:before {
  content: '\e672';
}

.ui-icon-check-on:before {
  content: '\e673';
}

.ui-icon-cancel:before {
  content: '\e674';
}

.ui-icon-forbidden:before {
  content: '\e674';
}

.ui-icon-close:before {
  content: '\e675';
}

.ui-icon-delete:before {
  content: '\e675';
}

.ui-icon-circle-close:before {
  content: '\e676';
}

.ui-icon-circle-b-close:before {
  content: '\e677';
}

.ui-icon-closethick:before {
  content: '\e678';
}

.ui-icon-squaresmall-close:before {
  content: '\e679';
}

.ui-icon-circlesmall-close:before {
  content: '\e67a';
}

.ui-icon-minus:before {
  content: '\e67b';
}

.ui-icon-circle-minus:before {
  content: '\e67c';
}

.ui-icon-circle-b-minus:before {
  content: '\e67d';
}

.ui-icon-minusthick:before {
  content: '\e67e';
}

.ui-icon-squaresmall-minus:before {
  content: '\e67f';
}

.ui-icon-circlesmall-minus:before {
  content: '\e680';
}

.ui-icon-plus:before {
  content: '\e681';
}

.ui-icon-circle-plus:before {
  content: '\e682';
}

.ui-icon-circle-b-plus:before {
  content: '\e683';
}

.ui-icon-plusthick:before {
  content: '\e684';
}

.ui-icon-squaresmall-plus:before {
  content: '\e685';
}

.ui-icon-circlesmall-plus:before {
  content: '\e686';
}

.ui-icon-pencil:before {
  content: '\e687';
}

.ui-icon-edit:before {
  content: '\e687';
}

.ui-icon-copy:before {
  content: '\e688';
}

.ui-icon-files:before {
  content: '\e688';
}

.ui-icon-scissors:before {
  content: '\e689';
}

.ui-icon-cut:before {
  content: '\e689';
}

.ui-icon-clipboard:before {
  content: '\e68a';
}

.ui-icon-paste:before {
  content: '\e68a';
}

.ui-icon-disk:before {
  content: '\e68b';
}

.ui-icon-save:before {
  content: '\e68b';
}

.ui-icon-undo:before {
  content: '\e68c';
}

.ui-icon-redo:before {
  content: '\e68d';
}

.ui-icon-rename:before {
  content: '\e68e';
}

.ui-icon-trash:before {
  content: '\e68f';
}

.ui-icon-recycle:before {
  content: '\e68f';
}

.ui-icon-print:before {
  content: '\e690';
}

.ui-icon-print-b:before {
  content: '\e691';
}

.ui-icon-note:before {
  content: '\e692';
}

.ui-icon-table:before {
  content: '\e693';
}

.ui-icon-tag:before {
  content: '\e694';
}

.ui-icon-ticket:before {
  content: '\e695';
}

.ui-icon-folder:before {
  content: '\e696';
}

.ui-icon-folder-collapsed:before {
  content: '\e696';
}

.ui-icon-folder-open:before {
  content: '\e697';
}

.ui-icon-folder-expanded:before {
  content: '\e697';
}

.ui-icon-document:before {
  content: '\e698';
}

.ui-icon-file:before {
  content: '\e698';
}

.ui-icon-document-b:before {
  content: '\e699';
}

.ui-icon-file-b:before {
  content: '\e699';
}

.ui-icon-audio:before {
  content: '\e69a';
}

.ui-icon-music:before {
  content: '\e69a';
}

.ui-icon-movie:before {
  content: '\e69b';
}

.ui-icon-film:before {
  content: '\e69b';
}

.ui-icon-video:before {
  content: '\e69c';
}

.ui-icon-image:before {
  content: '\e69d';
}

.ui-icon-picture:before {
  content: '\e69d';
}

.ui-icon-script:before {
  content: '\e69e';
}

.ui-icon-file-pdf:before {
  content: '\e69f';
}

.ui-icon-file-rtf:before {
  content: '\e6a0';
}

.ui-icon-file-spreadsheet:before {
  content: '\e6a1';
}

.ui-icon-file-xls:before {
  content: '\e6a1';
}

.ui-icon-file-txt:before {
  content: '\e6a2';
}

.ui-icon-file-text:before {
  content: '\e6a2';
}

.ui-icon-file-word:before {
  content: '\e6a3';
}

.ui-icon-file-zip:before {
  content: '\e6a4';
}

.ui-icon-play:before {
  content: '\e6a5';
}

.ui-icon-pause:before {
  content: '\e6a6';
}

.ui-icon-stop:before {
  content: '\e6a7';
}

.ui-icon-volume-off:before {
  content: '\e6a8';
}

.ui-icon-mute:before {
  content: '\e6a8';
}

.ui-icon-volume-mute:before {
  content: '\e6a8';
}

.ui-icon-volume-on:before {
  content: '\e6a9';
}

.ui-icon-volume-high:before {
  content: '\e6a9';
}

.ui-icon-volume-on-b:before {
  content: '\e6aa';
}

.ui-icon-volume-low:before {
  content: '\e6aa';
}

.ui-icon-shuffle:before {
  content: '\e6ab';
}

.ui-icon-retweet:before {
  content: '\e6ac';
}

.ui-icon-eject:before {
  content: '\e6ad';
}

.ui-icon-link:before {
  content: '\e6ae';
}

.ui-icon-extlink:before {
  content: '\e6af';
}

.ui-icon-linkext:before {
  content: '\e6af';
}

.ui-icon-action:before {
  content: '\e6af';
}

.ui-icon-link-broken:before {
  content: '\e6b0';
}

.ui-icon-locked:before {
  content: '\e6b1';
}

.ui-icon-unlocked:before {
  content: '\e6b2';
}

.ui-icon-window:before {
  content: '\e6b3';
}

.ui-icon-newwin:before {
  content: '\e6b4';
}

.ui-icon-popup:before {
  content: '\e6b4';
}

.ui-icon-dialog:before {
  content: '\e6b4';
}

.ui-icon-window-sidebar:before {
  content: '\e6b5';
}

.ui-icon-menu:before {
  content: '\e6b6';
}

.ui-icon-bars:before {
  content: '\e6b6';
}

.ui-icon-home:before {
  content: '\e6b7';
}

.ui-icon-bookmark:before {
  content: '\e6b8';
}

.ui-icon-bookmark-b:before {
  content: '\e6b9';
}

.ui-icon-star:before {
  content: '\e6ba';
}

.ui-icon-star-b:before {
  content: '\e6bb';
}

.ui-icon-addon:before {
  content: '\e6bc';
}

.ui-icon-puzzle:before {
  content: '\e6bc';
}

.ui-icon-plugin:before {
  content: '\e6bd';
}

.ui-icon-package:before {
  content: '\e6be';
}

.ui-icon-power:before {
  content: '\e6bf';
}

.ui-icon-switch:before {
  content: '\e6bf';
}

.ui-icon-refresh:before {
  content: '\e6c0';
}

.ui-icon-group:before {
  content: '\e6c1';
}

.ui-icon-users:before {
  content: '\e6c1';
}

.ui-icon-person:before {
  content: '\e6c2';
}

.ui-icon-user:before {
  content: '\e6c2';
}

.ui-icon-heart:before {
  content: '\e6c3';
}

.ui-icon-heart-b:before {
  content: '\e6c4';
}

.ui-icon-heart-beat:before {
  content: '\e6c5';
}

.ui-icon-key:before {
  content: '\e6c6';
}

.ui-icon-lightbulb:before {
  content: '\e6c7';
}

.ui-icon-cart:before {
  content: '\e6c8';
}

.ui-icon-shop:before {
  content: '\e6c8';
}

.ui-icon-cart-b:before {
  content: '\e6c9';
}

.ui-icon-clock:before {
  content: '\e6ca';
}

.ui-icon-clock-b:before {
  content: '\e6cb';
}

.ui-icon-cloud:before {
  content: '\e6cc';
}

.ui-icon-cloud-b:before {
  content: '\e6cd';
}

.ui-icon-cloud-download:before {
  content: '\e6ce';
}

.ui-icon-cloud-upload:before {
  content: '\e6cf';
}

.ui-icon-comment:before {
  content: '\e6d0';
}

.ui-icon-comments:before {
  content: '\e6d1';
}

.ui-icon-globe:before {
  content: '\e6d2';
}

.ui-icon-globe-b:before {
  content: '\e6d3';
}

.ui-icon-location:before {
  content: '\e6d4';
}

.ui-icon-navigation:before {
  content: '\e6d5';
}

.ui-icon-gear:before {
  content: '\e6d6';
}

.ui-icon-gears:before {
  content: '\e6d7';
}

.ui-icon-camera:before {
  content: '\e6d8';
}

.ui-icon-flag:before {
  content: '\e6d9';
}

.ui-icon-eye:before {
  content: '\e6da';
}

.ui-icon-box:before {
  content: '\e6db';
}

.ui-icon-transfer-e-w:before {
  content: '\e6dc';
}

.ui-icon-transferthick-e-w:before {
  content: '\e6dd';
}

.ui-icon-sign-in:before {
  content: '\e6de';
}

.ui-icon-login:before {
  content: '\e6de';
}

.ui-icon-sign-out:before {
  content: '\e6df';
}

.ui-icon-logout:before {
  content: '\e6df';
}

.ui-icon-logoff:before {
  content: '\e6df';
}

.ui-icon-toggle-off:before {
  content: '\e6e0';
}

.ui-icon-toggle-on:before {
  content: '\e6e1';
}

.ui-icon-search:before {
  content: '\e6e2';
}

.ui-icon-wrench:before {
  content: '\e6e3';
}

.ui-icon-settings:before {
  content: '\e6e4';
}

.ui-icon-radio-on:before {
  content: '\e6e5';
}

.ui-icon-selectbox:before {
  content: '\e6e6';
}

.ui-icon-suitcase:before {
  content: '\e6e7';
}

.ui-icon-truck:before {
  content: '\e6e8';
}

.ui-icon-database:before {
  content: '\e6e9';
}

.ui-icon-databases:before {
  content: '\e6ea';
}

.ui-icon-book:before {
  content: '\e6eb';
}

.ui-icon-book-b:before {
  content: '\e6ec';
}

.ui-icon-calculator:before {
  content: '\e6ed';
}

.ui-icon-calculator-b:before {
  content: '\e6ee';
}

.ui-icon-calendar:before {
  content: '\e6ef';
}

.ui-icon-calendar-b:before {
  content: '\e6f0';
}

.ui-icon-calendar-day:before {
  content: '\e6f1';
}

.ui-icon-address:before {
  content: '\e6f2';
}

.ui-icon-contact:before {
  content: '\e6f3';
}

.ui-icon-phone:before {
  content: '\e6f4';
}

.ui-icon-circle-phone:before {
  content: '\e6f5';
}

.ui-icon-mail-closed:before {
  content: '\e6f6';
}

.ui-icon-mail:before {
  content: '\e6f6';
}

.ui-icon-mail-open:before {
  content: '\e6f7';
}

.ui-icon-mail-reply:before {
  content: '\e6f8';
}

.ui-icon-mail-replyall:before {
  content: '\e6f9';
}

.ui-icon-mail-forward:before {
  content: '\e6fa';
}

.ui-icon-mail-send:before {
  content: '\e6fb';
}

.ui-icon-newspaper:before {
  content: '\e6fc';
}

.ui-icon-newsletter:before {
  content: '\e6fc';
}

.ui-icon-pin-s:before {
  content: '\e6fd';
}

.ui-icon-pin-w:before {
  content: '\e6fe';
}

.ui-icon-zoomin:before {
  content: '\e6ff';
}

.ui-icon-circle-zoomin:before {
  content: '\e700';
}

.ui-icon-zoomout:before {
  content: '\e701';
}

.ui-icon-circle-zoomout:before {
  content: '\e702';
}

.ui-icon-screen-display:before {
  content: '\e703';
}

.ui-icon-desktop:before {
  content: '\e703';
}

.ui-icon-screen-laptop:before {
  content: '\e704';
}

.ui-icon-screen-mobile:before {
  content: '\e705';
}

.ui-icon-mobile:before {
  content: '\e705';
}

.ui-icon-view-icons:before {
  content: '\e706';
}

.ui-icon-view-icons-b:before {
  content: '\e707';
}

.ui-icon-grid:before {
  content: '\e707';
}

.ui-icon-view-list:before {
  content: '\e708';
}

.ui-icon-list:before {
  content: '\e708';
}

.ui-icon-sorting:before {
  content: '\e709';
}

.ui-icon-sorting-asc:before {
  content: '\e70a';
}

.ui-icon-sorting-desc:before {
  content: '\e70b';
}

.ui-icon-battery-0:before {
  content: '\e70c';
}

.ui-icon-battery-1:before {
  content: '\e70d';
}

.ui-icon-battery-2:before {
  content: '\e70e';
}

.ui-icon-battery-3:before {
  content: '\e70f';
}

.ui-icon-signal:before {
  content: '\e710';
}

.ui-icon-signal-diag:before {
  content: '\e711';
}

.ui-icon-window-minimize:before {
  content: '\e717';
}

.ui-loading-icon.ui-icon-balls:before {
  content: '\e712';
}

.ui-loading-icon.ui-icon-circle:before {
  content: '\e713';
}

.ui-loading-icon.ui-icon-comet:before {
  content: '\e714';
}

.ui-loading-icon.ui-icon-lines:before {
  content: '\e715';
}

.ui-loading-icon.ui-icon-planet:before {
  content: '\e716';
}

@keyframes clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes translate {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100%, 0);
  }
}

@keyframes move-right {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}

@keyframes square-fill {
  0%, 25%, 100% {
    height: 0;
  }
  50%, 75% {
    height: 100%;
  }
}

@keyframes square-clockwise {
  0% {
    transform: rotate(0deg);
  }
  25%, 50% {
    transform: rotate(180deg);
  }
  75%, 100% {
    transform: rotate(360deg);
  }
}

.ui-animate.clockwise .ui-icon,
.ui-animate.anti-clockwise .ui-icon {
  animation: clockwise 1s infinite linear;
}

.ui-animate.anti-clockwise .ui-icon {
  animation-direction: reverse;
}

.ui-animate.bounce .ui-icon:before {
  animation: bounce 1s infinite ease-in-out;
}

.ui-loading-icon.ui-icon-circle, .ui-loading-icon.ui-icon-comet, .ui-loading-icon.ui-icon-planet {
  animation: clockwise 1s infinite linear;
}

.ui-loading-icon.ui-icon-balls, .ui-loading-icon.ui-icon-lines {
  animation: clockwise 1s infinite ease-in-out;
}

.ui-corner-all {
  border-radius: 0;
}

.ui-corner-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.ui-corner-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.ui-corner-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ui-corner-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.ui-corner-tl {
  border-top-left-radius: 0;
}

.ui-corner-tr {
  border-top-right-radius: 0;
}

.ui-corner-bl {
  border-bottom-left-radius: 0;
}

.ui-corner-br {
  border-bottom-right-radius: 0;
}

.ui-draggable-handle {
  touch-action: none;
}

.ui-resizable {
  position: relative;
}

.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  touch-action: none;
}

.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}

.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}

.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}

.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-se {
  cursor: se-resize;
  width: 16px;
  height: 20px;
  right: 1px;
  bottom: 1px;
}

.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}

.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}

.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}

.ui-selectable {
  touch-action: none;
}

.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted #696969;
}

.ui-sortable-handle {
  touch-action: none;
}

.ui-accordion {
  margin-bottom: 20px;
}

.ui-accordion h3.ui-accordion-header {
  display: block;
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 5px 10px;
  border-bottom-width: 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.ui-accordion .ui-accordion-content {
  padding: 10px;
  border-top: 0;
  overflow: auto;
}

.ui-accordion .ui-accordion-content[aria-expanded=true] {
  border-bottom-width: 0;
}

.ui-accordion .view-grouping:last-child h3.ui-accordion-header[aria-selected=false] {
  border-bottom-width: 1px;
}

.ui-accordion .view-grouping:last-child .ui-accordion-content[aria-expanded=true] {
  border-bottom-width: 1px;
}

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 700 !important;
}

.ui-button {
  display: inline-block;
  position: relative;
  padding: 5px 10px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  overflow: visible;
  touch-action: manipulation;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.ui-button, .ui-button:link, .ui-button:visited, .ui-button:hover, .ui-button:active {
  text-decoration: none;
}

.ui-button-icon-only .ui-button-text {
  display: none;
}

.ui-buttonset:before, .ui-buttonset:after {
  content: '';
  display: table;
  border-collapse: collapse;
}

.ui-buttonset:after {
  clear: both;
}

.ui-buttonset .ui-button {
  float: left;
  margin-right: -1px;
}

.ui-buttonset .ui-button.ui-state-active, .ui-buttonset .ui-button.ui-state-focus, .ui-buttonset .ui-button.ui-state-hover {
  z-index: 100;
}

.ui-datepicker {
  width: 300px;
  padding: 5px;
  display: none;
  z-index: 500 !important;
  overflow: visible;
  margin-bottom: 20px;
}

.ui-datepicker-header {
  position: relative;
  padding: 5px 5px;
  margin: 0;
  border-color: #EFF5F8;
  border-width: 1px;
  border-radius: 0;
  background: #F9FBFC;
  color: #000000;
}

.ui-datepicker-prev, .ui-datepicker-next {
  position: absolute;
  border: 1px solid transparent;
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
}

.ui-datepicker-prev span, .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: #000000;
  top: calc(50% - 12px);
}

.ui-datepicker-prev {
  left: 2.5px;
}

.ui-datepicker-next {
  right: 2.5px;
}

.ui-datepicker-title {
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.ui-datepicker-title select {
  font-size: 16px;
  margin: 1px 0;
  color: #000000;
}

.ui-datepicker-month, .ui-datepicker-year {
  width: calc(50% - 39px);
  font-weight: normal;
}

.ui-datepicker-month + .ui-datepicker-year {
  margin-left: 5px;
}

.ui-datepicker table {
  width: 100%;
  font-size: 14px;
  margin: 0 0 5px;
  border-collapse: collapse;
  table-layout: fixed;
}

.ui-datepicker th {
  padding: 12px 5px;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
  text-align: center;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: 6px 2px;
  text-decoration: none;
}

.ui-datepicker-buttonpane {
  text-align: left;
  border-width: 1px 0 0 0;
  background-image: none;
  padding: 5px 0 0;
  margin: 0;
}

.ui-datepicker-buttonpane:before, .ui-datepicker-buttonpane:after {
  content: '';
  display: table;
  border-collapse: collapse;
}

.ui-datepicker-buttonpane:after {
  clear: both;
}

.ui-datepicker-buttonpane button {
  float: right;
  cursor: pointer;
  padding: 5px 10px;
}

.ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker > *:last-child {
  margin-bottom: 0;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-header {
  border-radius: 0;
}

.ui-datepicker-multi .ui-datepicker-group:first-child .ui-datepicker-header {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.ui-datepicker-multi .ui-datepicker-group:nth-last-child(3) .ui-datepicker-header {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 5px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 5px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}

.ui-dialog {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  outline: 0;
  z-index: 800 !important;
}

.ui-dialog-titlebar {
  padding: 5px 10px;
  position: relative;
  border-radius: 0;
  border-width: 1px;
  background: #F9FBFC;
  margin: 0;
  color: #000000;
  border-color: #EFF5F8;
}

.ui-dialog-titlebar-close {
  position: absolute;
  right: 5px;
  top: calc(50% - 11px);
  width: 20px;
  height: 20px;
  padding: 1px;
}

.ui-dialog-title {
  float: left;
  width: calc(100% - 30px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui-dialog-content {
  position: relative;
  border: 0;
  padding: 15px;
  background: none;
  overflow: auto;
}

.ui-dialog-buttonpane {
  text-align: left;
  border-width: 1px 0 0 0;
  background-image: none;
  padding: 5px 0 0;
}

.ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right;
}

.ui-dialog-buttonpane button {
  cursor: pointer;
}

.ui-dialog-buttonpane button:not(:last-child) {
  margin-right: 10px;
}

.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}

.ui-menu {
  list-style: none;
  margin: 0 0 20px;
  display: block;
  outline: none;
  min-width: 150px;
  padding: 0;
  border-radius: 0;
  border-color: #EFF5F8;
  border-width: 1px;
}

.ui-menu .ui-menu {
  position: absolute;
  z-index: 700;
}

.ui-menu-item {
  margin: 0;
  cursor: pointer;
  position: relative;
  padding: 5px 10px;
  border: 1px solid transparent;
}

.ui-menu-item.ui-state-active, .ui-menu-item.ui-state-focus {
  border-color: transparent;
  background: #EAEAEA;
  outline: none;
}

.ui-menu-item.ui-state-active, .ui-menu-item.ui-state-active:active, .ui-menu-item.ui-state-active:hover, .ui-menu-item.ui-state-active:focus,
.ui-menu-item.ui-state-active a,
.ui-menu-item.ui-state-active a:active,
.ui-menu-item.ui-state-active a:hover,
.ui-menu-item.ui-state-active a:focus, .ui-menu-item.ui-state-focus, .ui-menu-item.ui-state-focus:active, .ui-menu-item.ui-state-focus:hover, .ui-menu-item.ui-state-focus:focus,
.ui-menu-item.ui-state-focus a,
.ui-menu-item.ui-state-focus a:active,
.ui-menu-item.ui-state-focus a:hover,
.ui-menu-item.ui-state-focus a:focus {
  color: #696969;
  text-decoration: none;
}

.ui-menu-divider {
  margin: 10px 0;
  border: 0;
  border-bottom: 1px solid #EFF5F8;
}

.ui-menu-icon {
  float: right;
}

.ui-progressbar {
  height: 20px;
  text-align: left;
  overflow: hidden;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #EFF5F8;
}

.ui-progressbar-value {
  margin: -1px;
  background: #F9FBFC;
  border: 1px solid #EFF5F8;
  height: 100%;
}

.ui-progressbar-overlay {
  height: 100%;
  opacity: 0.25;
}

.ui-progressbar-indeterminate .ui-progressbar-value {
  background-image: none;
}

.ui-selectmenu-menu {
  display: none;
  position: absolute;
  z-index: 700 !important;
}

.ui-selectmenu-menu .ui-menu {
  overflow: auto;
  overflow-x: hidden;
  margin: 0;
}

.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding: 5px 10px;
  height: auto;
  margin: 0px 0 10px;
  color: #000000;
}

.ui-selectmenu-open {
  display: block;
}

.ui-selectmenu-button {
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  padding: 5px 10px;
  line-height: 1.5;
}

.ui-selectmenu-button span.ui-icon {
  float: right;
}

.ui-selectmenu-button span.ui-selectmenu-text {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui-slider {
  position: relative;
  text-align: left;
  background: #ffffff;
  border: 1px solid #EFF5F8;
}

.ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 24px;
  height: 24px;
  cursor: pointer;
  touch-action: none;
}

.ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 14px;
  display: block;
  border: 0;
  background: #F9FBFC;
  border: 1px solid #EFF5F8;
}

.ui-slider-horizontal {
  height: 20px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: calc(50% - 13px);
  margin-left: -12px;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: 20px;
  height: 200px;
}

.ui-slider-vertical .ui-slider-handle {
  left: calc(50% - 13px);
  margin-left: 0;
  margin-bottom: -12px;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

.ui-spinner {
  position: relative;
  display: inline-block;
  padding: 0;
  vertical-align: middle;
  border: none;
}

.ui-spinner-input {
  position: relative;
  background: #fff;
  height: auto;
  color: inherit;
  margin: 0;
  padding: 5px 10px;
  vertical-align: middle;
  box-sizing: border-box;
  width: calc(100% - 20px);
  z-index: 20;
  border: 1px solid #EFF5F8;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.ui-spinner-input:focus {
  outline: 0;
  border-color: #9a9ca0;
}

.ui-spinner-button {
  width: 20px;
  height: calc(50% - 2px);
  font-size: 6px;
  padding: 0;
  margin: 0;
  text-align: center;
  position: absolute;
  cursor: pointer;
  display: block;
  overflow: hidden;
  right: 0;
}

.ui-spinner .ui-icon {
  position: absolute;
  left: 0;
  right: 0;
}

.ui-spinner-up {
  top: 0;
}

.ui-spinner-up .ui-icon {
  top: -3px;
}

.ui-spinner-down {
  bottom: 0;
  border-top: 0;
  padding-top: 1px;
}

.ui-spinner-down .ui-icon {
  top: -3px;
}

.ui-tabs {
  position: relative;
  padding: 5px;
  border: 1px solid #EFF5F8;
  margin-bottom: 20px;
}

.ui-tabs-nav {
  margin: 0;
  padding: 5px 5px 0;
  border-color: #EFF5F8;
  border-width: 1px;
  border-radius: 0;
  background: #F9FBFC;
}

.ui-tabs-nav .ui-tabs-anchor {
  float: left;
  padding: 5px 10px;
  text-decoration: none;
}

.ui-tabs-nav > li {
  list-style: none;
  float: left;
  display: block;
  position: relative;
  top: 0;
  margin: 1px 5px -1px 0;
  padding: 0;
}

.ui-tabs-nav > li.ui-tabs-active .ui-tabs-anchor, .ui-tabs-nav > li.ui-state-disabled .ui-tabs-anchor, .ui-tabs-nav > li.ui-tabs-loading .ui-tabs-anchor {
  cursor: text;
}

.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor {
  cursor: pointer;
}

.ui-tabs-panel {
  display: block;
  border-width: 0;
  padding: 5px 10px;
  background: none;
}

.ui-tooltip {
  padding: 10px;
  position: absolute;
  z-index: 600 !important;
  max-width: 300px;
  background: #ffffff;
  border: 1px solid #EFF5F8;
  border-radius: 0;
  color: #000000;
}

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
}

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: none;
}

.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  padding: 3px 1em 3px .8em;
  cursor: pointer;
  min-height: 0;
}

/*# sourceMappingURL=jquery-ui.css.map */